.color-container{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.color-picker-container{
	width: 85px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: 20px;
}

.color-picker-text{
	border-bottom: 1px solid #333333;
	color: #333333;
	line-height: 20px;
	margin-right: 5px;
}

.selected-color-btn{
	height: 25px;
	width: 25px;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid #4F4F4F;
}

.color-btns{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	cursor: pointer;
	margin: 2px 2px 2px 2px;
}
