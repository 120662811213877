@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.workflow-step > li{
  height: 50px;
  border-radius: 5px 0px 0px 5px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 15px 0px 10px;
	margin: 0;
  transition-delay: 5ms;
  transition-duration: 5ms;
	width: 100%;
}

.workflow-step > li::after {
  content: none;
}

.workflow-step > li:hover{
  background: #F2F2F2 !important;
	width: 100%;
}

.step-color-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.modify-btns-container{
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
  width: 80px;
}

.hide-edit{
  display: none;
}
.hide-delete{
  display: none;
}

.workflow-step > li:hover .hide-edit{
  display: flex !important;
  align-items: center;
  color: #333333;
}
.modify-btns-container:hover .hide-edit{
  color: #001DAD;
}

.workflow-step > li:hover .hide-delete{
  display: flex !important;
  align-items: center;
  color: red;
}

.step-color{
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin-right: 7px;
}

.workflow-title{
	font-size: 16px;
	line-height: 19px;
	color: #4F4F4F;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 15px 0px 0px;
	height: 50px;
	margin: 0;
}

.workflow-add-button-container{
  padding-right: 15px;
}

.workflow-add-button{
  display: flex;
  justify-content: center;
  align-items: center;
	height: 35px;
	background: #F2F2F2;
	border-radius: 5px;
	border: none;
	width: 100%;
	cursor: pointer;
}

.workflow-add-button:active, .workflow-add-button:focus, .workflow-add-button:hover{
	background: #ECF5FF !important;
  color: #4F4F4F !important;
}

.add-step-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  padding: 20px;
  min-height: 300px;
}

.step-input{
	height: 50px;
	background: #FFFFFF;
	border-radius: 5px;
  position: relative;
  padding-left: 30px !important;
}

.step-input:focus{
	outline: none !important;
	border: none !important;
}

.selected-color{
  position: absolute;
  height: 15px;
	width: 15px;
	border-radius: 50%;
  z-index: 2;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
}

.add-step-btn-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.step-btn{
	width: 120px;
	height: 35px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}
.step-btn:focus, .add{
	background: #001DAD;
	color: #FFFFFF;
	margin-left: 10px;
  border-color: #001DAD;
}

.add:hover{
  background: #306AE1;
  color: #FFFFFF;
}

.cancel{
	background: #F2F2F2;
	color: #333333;
}

.cancel:hover{
	background: #ECF5FF;
  color: #333333;
}

.drawer-container{
  width: 450px;
  margin: 10px 0px 0px 60px;
}

.drawer-container >.workflow-step > li{
  border-radius: 5px;
  background: #FFFFFF !important;
}
.drawer-container >.workflow-step > li:hover{
  background: #F2F2F2 !important;
}

.drawer-close-btn{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  line-height: 1;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, .45);
  transition-delay: 0s;
  transition-duration: 0.3s;
}

.drawer-close-btn:hover{
  color: rgba(0, 0, 0, .75);;
}

.radio-btn-container{
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.ant-radio-inner::after{
  background-color: #001DAD !important;
  border-color: #001DAD !important
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #001DAD !important;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #001DAD !important;
}

.arrow-back-btn{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  line-height: 1;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, .45);
  transition-delay: 0s;
  transition-duration: 0.3s;
}

.arrow-back-btn:hover{
  color: rgba(0, 0, 0, .75);;
}

.ant-select-selection:focus, .ant-select-selection:hover{
  border-color: #001DAD;
}

.select-option:hover, .select-option:focus{
  background-color: #001DAD !important;
  color: #FFFFFF;
}
